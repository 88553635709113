<template>
    <div class="container">
        <BreadcrumbNav :pageName="$t('project.title')"></BreadcrumbNav>
        <!--  one  -->
        <div class="project_nav auto">
            <ul class="fl classify_nav">
                <li :class="{classify_nav_active:tabIndex===0}" @click="changeTab(0)" class="fl cur">{{
                    $t("project.whole") }}
                </li>
                <li :class="{classify_nav_active:tabIndex===1}" @click="changeTab(1)" class="fl cur">{{
                    $t("project.talent") }}
                </li>
                <li :class="{classify_nav_active:tabIndex===2}" @click="changeTab(2)" class="fl cur">{{
                    $t("project.financing") }}
                </li>
                <li :class="{classify_nav_active:tabIndex===3}" @click="changeTab(3)" class="fl cur">{{
                    $t("project.smart") }}
                </li>
            </ul>
            <div class="classify_nav_right fr">
                <div class="search_box fl">
                    <input :placeholder="$t('project.keyword')" type="text" v-model="keyword">
                    <div @click="search()" class="cur fr">
                        <img alt="" height="18px" src="../../assets/images/icon_search@2x.png" width="18px">
                    </div>
                </div>
                <div @click="$router.push('addProject')" class="add_project cur fl">
                    + {{ $t("project.publish") }}
                </div>
                <div class="clearfix"></div>
            </div>
            <div class="clearfix"></div>
        </div>
        <!--  two  -->
        <div class="project_screen auto">
            <div class="fl screen_state">
                <span class="screen_title">{{ $t("project.state") }}</span>
                <el-dropdown @command="handleStatus">
                    <el-button size="mini" type="">
                        {{statusArray[status]}}<i class="el-icon-arrow-down el-icon--right"></i>
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item :command="0">{{ $t("project.whole") }}</el-dropdown-item>
                        <el-dropdown-item :command="1">{{ $t("project.progress") }}</el-dropdown-item>
                        <el-dropdown-item :command="2">{{ $t("project.end") }}</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
            <div class="fl screen_state" style="margin-left: 50px">
                <span class="screen_title">{{ $t("project.sort") }}</span>
                <el-dropdown @command="handleSort" class="paixu">
                    <el-button size="mini" type="">
                        {{sort===1? $t("project.composite"):$t("project.views")}}<i
                            class="el-icon-arrow-down el-icon--right"></i>
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item :command="1">{{ $t("project.composite") }}</el-dropdown-item>
                        <el-dropdown-item :command="2">{{ $t("project.views") }}</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
            <div class="fr screen_sum">
                <span>{{total}}</span> {{ $t("project.total") }}
            </div>
        </div>
        <!--  three  -->
        <div class="project_list auto">
            <div :key="index" @click="gopage(item)" class="project_item fl" v-for="(item,index) in list">
                <div class="img_box">
                    <img :src="item.project_cover_url" alt="" height="100%" style="object-fit: cover;"
                         v-if="item.cover_type==='2'" width="100%">
                    <img :src="item.project_banner[0]" alt="" height="100%" style="object-fit: cover;"
                         v-if="item.cover_type==='1'" width="100%">
                </div>
                <h2 class="over">{{ item.project_name }}</h2>
                <p class="over2" style="height: 40px">{{ item.project_intro }}</p>
                <div class="item_bottom">
                    <div class="see fl cur">
                        <img alt="" src="../../assets/images/icon_eye@2x.png" width="18px">
                        {{ item.view_count }}
                    </div>
                    <div class="see fl cur">
                        <img alt="" src="../../assets/images/icon_hart@2x.png" width="18px">
                        {{ item.follow_count }}
                    </div>
                </div>
            </div>
            <div class="clearfix"></div>
            <el-pagination
                    :current-page.sync="pageNum"
                    :page-size="pageSize"
                    :total="total"
                    @current-change="handleCurrentChange"
                    layout="prev, pager, next,slot"
                    style="text-align: center"
                    v-if="total!==0&&total"
            >
                <slot>
                    <div class="jump-page">
                        <span>{{ $t("project.go") }}</span>
                        <input type="text" v-model.number="goPageNum">
                        <i @click="goJumpPage(goPageNum)" class="el-icon-arrow-right"></i>
                    </div>
                </slot>
            </el-pagination>
        </div>

        <transition name="fade">
            <loading v-if="isLoading"></loading>
        </transition>
    </div>
</template>

<script>

    import BreadcrumbNav from "@/components/BreadcrumbNav";
    import {mainProjectList} from "@/api/projectAbout";
    import Loading from '@/components/loading'
    import {pageMixin} from '@/mixins';

    export default {
        name: "index",
        mixins: [pageMixin],
        data() {
            return {
                tabIndex: 0,
                keyword: '',
                sort: 1,
                status: 0,
                // statusArray:[this.$t("project.whole"),this.$t("project.progress"),this.$t("project.end")],
                isLoading: false,
            };
        },
        components: {
            BreadcrumbNav, Loading
        },
        created() {
            this.pageSize = 9;
            this.getDataList();
        },
        computed: {
            statusArray() {//计算属性
                return [this.$t("project.whole"), this.$t("project.progress"), this.$t("project.end")];
            },
        },
        methods: {
            gopage(item) {
                this.$router.push({
                    path: 'details/' + item.project_id,
                    query: {type: 1, projectCategory: item.project_category}
                });
            },
            //状态下拉选择
            handleStatus(command) {
                this.status = command;
                this.pageNum = 1;
                this.goPageNum = 1;
                this.getDataList();
            },
            //排序方式下拉选择
            handleSort(command) {
                this.sort = command;
                this.pageNum = 1;
                this.goPageNum = 1;
                this.getDataList();
            },
            //项目类型切换(0:全部；1：找人；2：找钱；3：找智慧)
            changeTab(index) {
                this.tabIndex = index;
                this.pageNum = 1;
                this.goPageNum = 1;
                this.getDataList();
            },
            goJumpPage(num) {
                this.pageNum = num;
                this.getDataList();
            },
            search() {
                this.pageNum = 1;
                this.goPageNum = 1;
                this.getDataList();
            },
            //请求数据列表（项目列表）
            getDataList() {
                let formData = {
                    projectType: this.tabIndex,
                    keyword: this.keyword,
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                    sort: this.sort,
                    status: this.status,
                }
                this.isLoading = true;
                mainProjectList(formData).then((res) => {
                    this.total = res.data?.total ?? 0;
                    this.list = res.data?.list ?? [];
                    this.isLoading = false;
                    // this.getVideoImage();
                })
            },
            handleCurrentChange: function handleCurrentChange(p) {
                this.pageNum = p;
                this.getDataList();
            },
            //获取视频预览图
            getVideoImage() {
                let self = this;
                this.list.forEach((item) => {
                    if (item.cover_type === '1') {
                        let video = document.createElement('video');
                        video.setAttribute('crossOrigin', 'Anonymous')
                        video.src = item.src;
                        //如果不设置currentTime，画出来的图片是空的
                        video.currentTime = 0.001;
                        video.onloadeddata = loadedmetadata;
                        // console.log("获取解惑")
                        // console.log(item.poster)
                        // console.log(video.onloadeddata)
                        // // console.log(widthBg)
                        // // console.log(heightBg)
                        // eslint-disable-next-line no-inner-declarations
                        function loadedmetadata(e) {
                            console.log("获取解惑")

                            let canvas = document.createElement('canvas');
                            let vWidth = e.path[0].videoWidth;
                            let vHeight = e.path[0].videoHeight;
                            //判断视频的高度和宽度
                            //画布的大小，由设置的视频显示的大小决定，以免画布太大，画的图片太大，浪费资源，这里设置显示视频盒子为width:140px，height:140px;
                            //设置倍数是rate，倍数越大画图的图片越大，加载速度越慢
                            //https://www.w3school.com.cn/html5/canvas_drawimage.asp
                            let rate = 4;
                            canvas.width = 140 * 4;
                            canvas.height = vHeight * 140 / vWidth * 4;
                            canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
                            let url = canvas.toDataURL('image/png', 1);
                            //显示背景图片的大小，保证在不压缩图片的情况下，背景图片能铺满
                            let widthBg, heightBg;
                            if (vWidth < vHeight) {
                                widthBg = 140;
                                heightBg = vHeight * 140 / vWidth;
                            } else {
                                widthBg = vWidth * 140 / vHeight;
                                heightBg = 140;
                            }
                            self.$set(item, 'poster', url);
                            self.$set(item, 'width', widthBg)
                            self.$set(item, 'height', heightBg)
                        }

                    }

                });

            },
        },
    }
</script>

<style scoped>
    .container {
        width: 100%;
        min-height: 100%;
    }

    .project_nav {
        width: 1200px;
        margin-top: 31px;
        line-height: 40px;
        margin-bottom: 28px;
    }

    .classify_nav li {
        width: 140px;
        height: 40px;
        border: 1px solid #707070;
        border-radius: 131px;
        box-sizing: border-box;
        line-height: 38px;
        text-align: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        margin-right: 20px;
        transition: .3s all;
    }


    .classify_nav li:hover {
        color: #FFFFFF !important;
        background: #000000 !important;
    }

    .classify_nav li:nth-child(4) {
        margin-right: 0;
    }

    .classify_nav_active {
        color: #FFFFFF !important;
        background: #000000 !important;
    }

    .search_box {
        width: 343px;
        height: 40px;
        background: #FAFAFA;
        border-radius: 131px;
        padding-left: 18px;
        box-sizing: border-box;
    }

    .search_box input {
        background: #FAFAFA;
        width: 200px;
    }

    .search_box > div {
        width: 65px;
        height: 40px;
        background: #000000;
        border-radius: 131px;
        text-align: center;
        line-height: 38px;
    }

    .add_project {
        margin-left: 20px;
        width: 124px;
        height: 40px;
        background: #000000;
        border-radius: 131px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
        text-align: center;
    }

    .project_screen {
        width: 1200px;
        height: 47px;
        background: #FAFAFA;
        line-height: 47px;
        padding: 0 17px;
    }

    .el-dropdown button {
        width: 160px;
        height: 32px;
        background: #FFFFFF;
        border-radius: 131px;
        border: none;
    }

    .paixu button {
        width: 190px !important;
    }

    .screen_title {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #949494;
        margin-right: 16px;
    }

    .el-button:focus, .el-button:hover {
        color: #000000 !important;
    }

    .screen_sum {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #949494;
    }

    .screen_sum span {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000 !important;
        padding: 0 5px;
    }

    .project_list {
        width: 1250px;
        min-height: 700px;
        margin-top: 27px;
        margin-bottom: 30px;
    }

    .project_item {
        width: 412px;
        height: 410px;
        background: #FFFFFF;
        border-radius: 10px;
        box-sizing: border-box;
        margin-right: 5px;
        transition: .3s all;
        padding: 18px 14px;
        margin-bottom: 24px;
    }

    .project_item:nth-child(3n) {
        margin-right: 0;
    }

    .project_item:hover {
        z-index: 999;
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.09);
    }

    .img_box {
        width: 100%;
        height: 250px;
        background: rgba(0, 0, 0, 0);
        border-radius: 4px;
        overflow: hidden;
    }

    .project_item > h2 {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 21px;
        color: #000000;
        margin-top: 22px;
    }

    .project_item > p {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 20px;
        color: #949494;
        margin-top: 5px;
    }

    .item_bottom {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 16px;
        color: #000000;
        margin-top: 8px;
    }

    .item_bottom img {
        margin-bottom: 3px;
    }

    .item_bottom .see {
        margin-right: 48px;
    }

    .jump-page {
        display: inline-block;
        vertical-align: middle;
        width: 124px;
        height: 40px;
        background: #FAFAFA;
        border-radius: 131px;
        margin-left: 30px;
        line-height: 40px !important;
        text-align: center;

    }

    .jump-page > span {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        vertical-align: middle;
    }

    .jump-page > input {
        background: transparent;
        width: 40px;
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        margin-top: 2px;
    }

    .jump-page > i {
        cursor: pointer;
        display: inline-block;
        vertical-align: middle;
        font-weight: 700;
        font-size: 19px;
    }
</style>
